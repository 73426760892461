$(document).foundation();
$(document).ready(function () {

    $("#owl-demo").owlCarousel({

        autoPlay: 3000,
        stopOnHover: true,
        navigation: true,
        paginationSpeed: 1000,
        goToFirstSpeed: 2000,
        singleItem: true,

        autoHeight: true,
        items: 1,
        lazyLoad: true
            //"singleItem:true" is a shortcut for:
            // items : 1, 
            // itemsDesktop : false,
            // itemsDesktopSmall : false,
            // itemsTablet: false,
            // itemsMobile : false


    });

});